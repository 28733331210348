.image-box {
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.astro-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.image-box-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgba(255,255,255,0) 60%, rgba(20, 22, 27, 1) 95%);
}

@media only screen and (min-width: 768px) {
    .image-box-gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(90deg, rgba(255,255,255,0) 60%, rgba(20, 22, 27, 1) 95%);
    }
}

.spinner {
    border: 4px solid #f3f3f3; /* Light gray border */
    border-top: 4px solid #368ef5; /* Blue border for the spinner color */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite; /* Animation for spinning */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px; /* Half of the height to center vertically */
    margin-left: -12px; /* Half of the width to center horizontally */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
